import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';

const Title = ({ as, children, sx }) => {
  const titleSx = {
    color: 'grays.5',
    fontSize: 4,
    fontWeight: 'bold',
    mb: 2,
    textTransform: 'uppercase',
    ...sx,
  };
  return (
    <Box as={as} sx={titleSx}>
      {children}
    </Box>
  );
};

Title.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

Title.defaultProps = {
  as: 'h2',
  sx: {},
};

export default Title;
