/* eslint-disable sort-keys */
import React from 'react';

export const addresses = {
  virginia: {
    address: (
      <>
        5180 Parkstone Drive, Suite 250
        <br />
        Chantilly, VA 20151-3812
        <br />
      </>
    ),
    contacts: [
      { number: '(703) 560-2200', type: 'Main' },
      { number: '(703) 277-3473', type: 'Fax' },
    ],
  },
  maryland: {
    address: (
      <>
        34 Atherton Road <br /> Lutherville, MD 21093-5704
      </>
    ),
    contacts: [{ number: '(410) 941-9020', type: 'Main' }],
  },
};
