export const experienceList = [
  {
    link: '/experience/k-12',
    title: 'K-12',
  },
  {
    link: '/experience/higher-ed',
    title: 'Higher Education',
  },
  {
    link: '/experience/military',
    title: 'Military',
  },
  {
    link: '/experience/federal',
    title: 'Federal',
  },
  {
    link: '/experience/state-and-local',
    title: 'State & Local',
  },
  {
    link: '/experience/healthCare',
    title: 'HealthCare',
  },
  {
    link: '/experience/culture',
    title: 'Culture',
  },
  {
    link: '/experience/transportation-and-Infrastructure',
    title: 'Transportation & Infrastructure',
  },
];
