import React, { useState } from 'react';
import Box from '../../base/Box';
import NavGroup from '../NavGroup';
import { pages } from '../../data/pages';
import menuIcon from '../../images/icons/menu-icon.svg';
import menuClose from '../../images/icons/close-icon.svg';
import Img from '../Img';

const NavBar = () => {
  const [toggled, setToggled] = useState(false);
  const toggleStyled = toggled
    ? { p: 1, position: 'fixed', right: '13px', top: '13px', zIndex: '20' }
    : {};

  return (
    <>
      <Box
        as="nav"
        sx={{
          bg: ['rgba(255, 255, 255, .98)', null, null, 'unset', null],
          bottom: ['0', null, null, 'unset', null],
          boxShadow: [toggled ? 3 : '', null, null, 'unset', null],
          display: 'flex',
          flexDirection: ['column', null, null, 'row', null],
          justifyContent: ['center', null, null, 'unset', null],
          left: ['auto', null, null, 'unset', null],
          maxWidth: ['480px', null, null, 'unset', null],
          mr: [null, null, null, -3, null],
          position: ['fixed', null, null, 'static', null],
          px: [2, null, null, 'unset', null],
          right: ['0', null, null, 'unset', null],
          top: ['0', null, null, 'unset', null],
          transform: [
            `${toggled ? 'translateX(0%)' : 'translateX(100%)'}`,
            null,
            null,
            'unset',
            null,
          ],
          transition: 'all .35s ease-in-out',
          width: ['80%', 'calc(80% - 30px)', null, 'unset', null],
          zIndex: ['10', null, null, 'unset', null],
        }}
      >
        <NavGroup list={pages} />
      </Box>
      <Box
        sx={{
          display: ['block', null, null, 'none', null],
          height: '40px',
          position: 'relative',
          width: '40px',
        }}
      >
        <Box
          onClick={() => setToggled(!toggled)}
          sx={{
            height: '40px',
            p: 1,
            top: 0,
            width: '40px',
            ...toggleStyled,
          }}
        >
          <Img alt="Menu" src={toggled ? menuClose : menuIcon} />
        </Box>
      </Box>
    </>
  );
};

export default NavBar;
