import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Logo from '../Logo';
import NavBar from '../NavBar';
import Container from '../Container';

const Header = ({ fixed, headerSx }) => {
  const headerFixedStyled = fixed
    ? {
        '& ~ main': {
          mt: ['60px', null, null, '77px', null],
        },
        boxShadow: 2,
        left: '0',
        position: 'fixed',
        right: '0',
        top: '0',
        zIndex: '99',
      }
    : {};
  const headerStyled = {
    bg: 'white',
    py: [2, null, null, 4, null],
    ...headerFixedStyled,
    ...headerSx,
  };
  return (
    <Box as="header" sx={headerStyled}>
      <Container
        sx={{
          alignItems: ['center', null, null, 'flex-end', null],
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: 'maxWidths.content',
          mx: 'auto',
        }}
      >
        <Logo
          sx={{
            maxWidth: ['145px', null, null, '270px', null],
          }}
        />
        <NavBar />
      </Container>
    </Box>
  );
};

Header.propTypes = {
  fixed: PropTypes.bool,
  headerSx: PropTypes.shape({}),
};

Header.defaultProps = {
  fixed: false,
  headerSx: {},
};

export default Header;
