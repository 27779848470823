import React from 'react';
import Box from '../../base/Box';
import Container from '../Container';
import Logo from '../Logo';
import { footerLinks, pages } from '../../data/pages';
import { addresses } from '../../data/addresses';
import LinkTo from '../LinkTo';
import ListGroup from '../ListGroup';

const Footer = () => {
  const colStyled = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: ['column', null, null, 'row', null],
    py: 2,
  };

  const linkStyled = {
    color: 'grays.12',
    fontFamily: 'tertiary',
    fontSize: 1,
    fontWeight: 'regular',
    px: 1,
    py: 1,
    textDecoration: 'none',
  };

  const listGroupStyled = {
    titleSx: {
      color: 'white',
      fontSize: 2,
    },
    wrapperSx: {
      maxWidth: ['100%', null, '145px', null, null],
      mb: 4,
      mr: 7,
    },
  };

  const divider = () => (
    <Box
      as="span"
      sx={{
        display: ['none', null, null, 'block', null],
        px: 2,
      }}
    >
      |
    </Box>
  );

  const setCopyRight = () => (
    <>
      <Box as="span" sx={linkStyled}>
        © {new Date().getFullYear()}. Forella. All Rights Reserved.
      </Box>
      {divider()}
      <Box as="span" sx={linkStyled}>
        Web Design by N Studio.
      </Box>
    </>
  );

  const setFooterLinks = () =>
    footerLinks.map((link, index) => (
      <Box key={index} sx={{ alignItems: 'center', display: 'flex' }}>
        <LinkTo sx={linkStyled} to={link.path}>
          {link.label}
        </LinkTo>
        {footerLinks.length - 1 !== index && divider()}
      </Box>
    ));

  return (
    <>
      <Box
        sx={{
          bg: 'grays.5',
          color: 'white',
          fontSize: 0,
          pt: 5,
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ListGroup
            title="Quicklinks"
            titleSx={listGroupStyled.titleSx}
            wrapperSx={listGroupStyled.wrapperSx}
          >
            {pages.length > 0 &&
              pages.map((link, index) => (
                <LinkTo
                  key={`footerLink-${index}`}
                  activeSx={{
                    color: 'warning',
                  }}
                  sx={{ textDecoration: 'none' }}
                  to={link.path}
                >
                  {link.label}
                </LinkTo>
              ))}
          </ListGroup>
          {Object.keys(addresses).length > 0 &&
            Object.keys(addresses).map((address, i) => {
              const info = addresses[address];
              return (
                <ListGroup
                  key={i}
                  title={address}
                  titleSx={listGroupStyled.titleSx}
                  wrapperSx={listGroupStyled.wrapperSx}
                >
                  <p>{info.address}</p>
                  <br />
                  {info.contacts.length > 0 &&
                    info.contacts.map((contact, index) => (
                      <Box
                        key={`${address}-contact-${index}`}
                        as="a"
                        href={`tel:${contact.number}`}
                        sx={{
                          '&:hover': { color: 'warning' },
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                      >
                        {`${contact.number} ${contact.type}`}
                      </Box>
                    ))}
                </ListGroup>
              );
            })}
          {/* Hide social media icons */}
          {/* {socialMedia && ( */}
          {/*  <Box */}
          {/*    sx={{ */}
          {/*      mb: 4, */}
          {/*      ml: 'auto', */}
          {/*      mr: ['auto', null, null, -1, null], */}
          {/*      mt: 'auto', */}
          {/*    }} */}
          {/*  > */}
          {/*    {socialMedia.map((sm, index) => ( */}
          {/*      <Box */}
          {/*        key={`social-media-${index}`} */}
          {/*        as="a" */}
          {/*        href={sm.link} */}
          {/*        sx={{ mx: 1 }} */}
          {/*        target="_blank" */}
          {/*      > */}
          {/*        <Img src={sm.icon} sx={{ width: '30px' }} /> */}
          {/*      </Box> */}
          {/*    ))} */}
          {/*  </Box> */}
          {/* )} */}
        </Container>
      </Box>
      <Box
        sx={{
          bg: 'grays.8',
          color: 'white',
          fontSize: 0,
        }}
      >
        <Container
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: ['column', null, null, 'row', null],
            justifyContent: 'space-between',
            textAlign: 'center',
          }}
        >
          <Box sx={colStyled}>
            <Logo
              light
              sx={{
                maxWidth: '145px',
                mr: [null, null, null, 2, null],
                py: '14px',
              }}
            />
            {setCopyRight()}
          </Box>
          <Box sx={colStyled}>{setFooterLinks()}</Box>
        </Container>
      </Box>
    </>
  );
};
export default Footer;
