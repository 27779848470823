import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';

const Img = ({ src, sx, ...rest }) => {
  const imgStyled = {
    display: 'inline-block',
    maxWidth: '100%',
    verticalAlign: 'top',
    ...sx,
  };
  return <Box as="img" src={src} sx={imgStyled} {...rest} />;
};
Img.propTypes = {
  src: PropTypes.string,
  sx: PropTypes.shape({}),
};

Img.defaultProps = {
  src: '',
  sx: {},
};

export default Img;
