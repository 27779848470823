import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import Box from '../../base/Box';

const LinkTo = ({ to, children, sx, activeSx }) => {
  const hasLocation = (location, path) => {
    const { pathname } = location;
    // const allPaths = [];
    // pathname
    //   .split('/')
    //   .filter((p) => p)
    //   .forEach((p, index) => {
    //     const prev = allPaths[index - 1] && allPaths[index - 1];
    //     allPaths.push(prev ? `${prev}/${p}` : `/${p}`);
    //   });
    return pathname.includes(path);
  };
  const activeStyled = {
    color: 'warning',
    ...activeSx,
  };
  const linkSx = {
    '&:hover': {
      color: 'warning',
    },
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    // textDecoration: 'underline',
    ...sx,
  };
  return (
    <Location>
      {({ location }) => (
        <Box
          as={Link}
          // partiallyActive
          sx={
            hasLocation(location, to) ? { ...linkSx, ...activeStyled } : linkSx
          }
          to={to}
        >
          {children}
        </Box>
      )}
    </Location>
  );
};

LinkTo.propTypes = {
  activeSx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
  to: PropTypes.string,
};

LinkTo.defaultProps = {
  activeSx: {},
  sx: {},
  to: '/',
};

export default LinkTo;
