import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import AppBase from '../AppBase';
import Footer from '../Footer';
import Header from '../Header';
import config from '../../config';
import theme from '../../theme';
import '../../fonts/lato/500.css';
import '../../fonts/lato/600.css';
import '../../fonts/lato/700.css';
import '../../fonts/lato/900.css';
import '../../fonts/opensans/600.css';
import '../../fonts/roboto/300.css';
import '../../fonts/roboto/400.css';
import '../../fonts/roboto/500.css';
import '../../fonts/roboto/700.css';
import '../../fonts/roboto/900.css';

const Layout = ({ children, ...rest }) => (
  <AppBase config={config} theme={theme}>
    <Box
      sx={{
        left: '0',
        // overflowX: 'hidden',
        position: 'relative',
        top: '0',
        width: '100%',
      }}
    >
      <Header fixed />
      <Box
        as="main"
        sx={{
          flex: 'auto',
        }}
      >
        {typeof children === 'function' ? children(rest) : children}
      </Box>
      <Footer />
    </Box>
  </AppBase>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default Layout;
