import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import LinkTo from '../LinkTo';

const NavGroup = ({ list, type }) => {
  const menuStyled = {
    '& li': {
      '&:hover': {
        '> a': {
          color: 'warning',
        },
        '> ul': {
          maxHeight: '500px',
          opacity: 1,
        },
      },
    },
    color: 'grays.3',
    display: 'flex',
    flexDirection: ['column', null, null, 'row', null],
    fontFamily: 'primary',
    fontSize: [3, null, null, 2, null],
    fontWeight: 'medium',
    justifyContent: ['center', null, null, 'unset', null],
    textAlign: ['left', null, null, 'unset', null],
    textTransform: 'uppercase',
  };
  const menuActiveStyled = {
    '&:after': {
      maxWidth: ['30px', null, null, 'calc(100% - 30px)', null],
    },
    color: 'grays.9',
  };
  const subMenuStyled = {
    ...menuStyled,
    '& li': {
      '&:hover': [
        null,
        null,
        null,
        {
          a: {
            color: 'warning',
          },
        },
        null,
      ],
    },
    bg: [null, null, null, 'white', null],
    borderBottom: ['1px solid', null, null, 'unset', null],
    borderColor: ['grays.2', null, null, 'unset', null],
    bottom: 'auto',
    boxShadow: [null, null, null, 3, null],
    color: ['grays.4', null, null, 'unset', null],
    flexDirection: 'column',
    fontFamily: 'tertiary',
    fontWeight: 'regular',
    justifyContent: ['center', null, null, 'unset', null],
    left: 0,
    lineHeight: '14px',
    maxHeight: '0',
    mb: [1, null, null, 'unset', null],
    minWidth: '160px',
    mx: [2, null, null, 'unset', null],
    opacity: 0,
    overflow: 'hidden',
    position: [null, null, null, 'absolute', null],
    right: 'auto',
    textTransform: 'capitalize',
    top: '100%',
    transition: 'all .55s ease-in-out',
    zIndex: [null, null, null, '10', null],
  };
  return (
    <Box as="ul" sx={type ? subMenuStyled : menuStyled}>
      {list &&
        list.length > 0 &&
        list.map((page) => {
          const isHome = page.label === 'Home';
          return !isHome ? (
            <Box
              key={`nav-page-${page.label}`}
              as="li"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <LinkTo
                activeSx={type ? {} : menuActiveStyled}
                as={Link}
                sx={{
                  '&::after': type
                    ? {}
                    : {
                        bg: 'warning',
                        borderRadius: 1,
                        bottom: 1,
                        content: '""',
                        height: '1px',
                        left: [4, null, null, '50%', null],
                        maxWidth: 0,
                        position: 'absolute',
                        transform: 'translateX(-50%)',
                        transition: 'max-width 0.35s ease-in-out 0s',
                        width: '100%',
                      },
                  '&:hover': {
                    ':after': {
                      maxWidth: ['30px', null, null, 'calc(100% - 30px)', null],
                    },
                  },
                  color: 'inherit',
                  display: 'inline-block',
                  position: 'relative',
                  px: [1, null, null, 3, null],
                  py: 2,
                  textDecoration: 'none',
                }}
                to={page.path}
              >
                {page.label}
              </LinkTo>
              {page.submenu && page.submenu.length > 0 && (
                <NavGroup list={page.submenu} type="submenu" />
              )}
            </Box>
          ) : null;
        })}
    </Box>
  );
};

NavGroup.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string,
};

NavGroup.defaultProps = {
  type: null,
};

export default NavGroup;
