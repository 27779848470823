/* eslint-disable sort-keys */
export const servicesData = {
  cost_estimating_analyses_and_controls: {
    title: 'Cost Estimating Analyses & Controls',
  },
  cpm_p6_schedule_management: {
    title: 'CPM P6 Schedule Management',
  },
  constructability_reviews: {
    title: 'Constructability Reviews',
  },
  project_management: {
    title: 'Project Management, Owner Services',
  },
  expert_investigations_and_dispute_resolution: {
    title: 'Expert Investigations & Dispute Resolution',
  },
  construction_economics: {
    title: 'Construction Economics',
  },
};
