import { experienceList } from './experienceList';
import { servicesData } from './servicesData';
import { aboutUsData } from './aboutUsData';
import linkedinIcon from '../images/linkedin.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';

export const experienceSubPages = Object.keys(experienceList).map(
  (experience) => {
    const item = experienceList[experience];
    return {
      label: item.title,
      path: item.link,
    };
  }
);
// const experienceFirstPage = experienceSubPages[0].path;

export const servicesSubPages = Object.keys(servicesData).map((services) => {
  const item = servicesData[services];
  return {
    label: item.title,
    path: `/services/${services}`,
  };
});

// const serviceFirstPage = servicesSubPages[0].path;
export const aboutUsSubPages = Object.keys(aboutUsData).map((about) => {
  const item = aboutUsData[about];
  return {
    label: item.titleNav,
    path: `/about-us/${about}`,
  };
});

export const pages = [
  { label: 'Home', path: '/home' },
  { label: 'About us', path: '/about-us', submenu: aboutUsSubPages },
  { label: 'Services', path: '/services', submenu: servicesSubPages },
  {
    label: 'Experience',
    path: '/experience',
    submenu: experienceSubPages,
  },
  { label: 'Careers', path: '/careers' },
  { label: 'Contact us', path: '/contact-us' },
];

export const footerLinks = [
  {
    label: 'Disclaimer',
    path: '/disclaimer',
  },
  {
    label: 'Home',
    path: '/home',
  },
  {
    label: 'Contact Us',
    path: '/contact-us',
  },
];

export const socialMedia = [
  {
    icon: linkedinIcon,
    link: 'https://www.linkedin.com/company/forella-group-llc',
  },
  {
    icon: facebookIcon,
    link: '/',
  },
  {
    icon: twitterIcon,
    link: '/',
  },
];
