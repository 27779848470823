/* eslint-disable prefer-destructuring */

import { darken } from 'polished';

const brandBlue = '#132A63';
const blue = '#1858B7';
const green = '#1de91d';
const yellow = '#DEAD00';
const red = '#ff2020';
const white = '#ffffff';

const grays = [
  '#F6F6F6',
  '#DADADA',
  '#C4C4C4',
  '#6B6B6B',
  '#636363',
  '#5A5A5A',
  '#3D3C3B',
  '#312F2F',
  '#393939',
  '#000000',
  '#717171',
  '#E5E5E5',
  '#F7F7F7',
];

const breakpoints = ['300px', '600px', '900px', '1220px', '1500px'];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

// const fontSizeScale = 1.2;

export default {
  baseFontSizesForBreakpoint: ['5vw', '4vw', '2.5vw', '1.5vw', '18px'],
  breakpoints,
  colors: {
    blue,
    body: white,
    border: grays[0],
    brandBlue,
    error: red,
    grays,
    primary: brandBlue,
    primaryBg: brandBlue,
    primaryDark: darken(0.05, brandBlue),
    success: green,
    text: {
      heading: grays[6],
      link: grays[2],
      primary: grays[1],
      // subtle: grays[0],
    },
    warning: yellow,
    white,
  },
  fonts: {
    mono: 'Menlo, monospace',
    primary: '"Lato", sans-serif',
    secondary: '"Open Sans", sans-serif',
    tertiary: '"Roboto", sans-serif',
  },
  fontSizes: [
    '10px',
    '12px',
    '14px',
    '15px',
    '18px',
    '20px',
    '24px',
    '26px',
    '28px',
    '30px',
    '54px',
  ],
  fontWeights: {
    black: 900,
    bold: 700,
    medium: 500,
    regular: 400,
    semiBold: 600,
  },
  letterSpacings: ['0.05em', '0.1em', '0.125em'],
  lineHeights: ['1.4em', '1.6em', '2em'],
  mediaQueries: {
    lg: `@media screen and (min-width: ${breakpoints[3]})`,
    md: `@media screen and (min-width: ${breakpoints[2]})`,
    sm: `@media screen and (min-width: ${breakpoints[1]})`,
    xl: `@media screen and (min-width: ${breakpoints[4]})`,
    xs: `@media screen and (min-width: ${breakpoints[0]})`,
  },
  radii: ['2px', '5px', '10px'],
  shadows: [
    'none',
    '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  ],
  sizes: {
    heights: { input: `calc(1.6em + 0.75rem * 2)` },
    maxWidths: {
      button: '14rem',
      content: '1260px',
      lg: '1024px',
      md: '820px',
      paragraph: '38em',
      sm: '500px',
    },
  },
  space: [
    '0',
    '5px',
    '10px',
    '15px',
    '20px',
    '30px',
    '50px',
    '60px',
    '120px',
    '150px',
    '115px',
  ],
  variants: {
    buttons: {
      primary: {
        '&:hover': { bg: 'primaryDark' },
        bg: 'primary',
        borderColor: 'transparent',
        borderRadius: 1,
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: 1,
        color: 'white',
        fontSize: 2,
        fontWeight: 'bold',
        height: 'heights.input',
        letterSpacing: 0,
        px: 7,
        py: 4,
        textTransform: 'uppercase',
        transition: 'background-color 0.2s, border-color 0.2s, color 0.2s',
      },
    },
    inputs: {
      primary: {
        '&::placeholder': { color: 'grays.3' },
        '&:focus': { borderColor: 'warning' },
        bg: 'rgba(229,229,229,0.4)',
        borderColor: 'transparent',
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: 'grays.9',
        fontSize: 1,
        pl: '22px',
        pr: 2,
        py: '7px',
      },
    },
  },
};
