import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Title from '../Title';

const ListGroup = ({
  title,
  children,
  content,
  listSx,
  paras,
  sx,
  titleSx,
  wrapperSx,
}) => {
  const textAreaStyled = {
    fontWeight: 'medium',
    listStyle: 'none',
    mb: 0,
    px: 0,
    ...sx,
  };
  const listStyled = {
    '&:last-of-type': {
      mb: 0,
    },
    ...listSx,
  };
  const titleStyled = {
    mb: 3,
    ...titleSx,
  };

  const wrapChild = (child) => (
    <Box as={paras ? 'p' : 'li'} sx={listStyled}>
      {child}
    </Box>
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'tertiary',
        lineHeight: 1,
        ...wrapperSx,
      }}
    >
      {title && <Title sx={titleStyled}>{title}</Title>}
      {content || (
        <Box as={!paras && 'ul'} sx={textAreaStyled}>
          {children.length > 0
            ? React.Children.map(children.filter(Boolean), (child) =>
                wrapChild(child)
              )
            : wrapChild(children)}
        </Box>
      )}
    </Box>
  );
};

ListGroup.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node,
  listSx: PropTypes.shape({}),
  paras: PropTypes.bool,
  sx: PropTypes.shape({}),
  title: PropTypes.string,
  titleSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

ListGroup.defaultProps = {
  content: null,
  listSx: {},
  paras: false,
  sx: {},
  title: '',
  titleSx: {},
  wrapperSx: {},
};

export default ListGroup;
