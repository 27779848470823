/* eslint-disable sort-keys */
export const aboutUsData = {
  overview: {
    title: 'Overview',
    titleNav: 'Overview',
  },
  mbe: {
    title: 'Minority Business Enterprise',
    titleNav: 'Minority Business Enterprise (MBE)',
  },
};
