import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';

const Container = ({ children, sx }) => {
  const containerStyled = {
    m: '0 auto',
    maxWidth: ['100%', null, null, 'maxWidths.content', null],
    px: 3,
    ...sx,
  };
  return <Box sx={containerStyled}>{children}</Box>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

Container.defaultProps = {
  sx: {},
};

export default Container;
