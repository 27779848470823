import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from '../../base/Box';
import brandLogo from '../../images/forella-logo.png';
import brandLogoWhite from '../../images/forella-logo-white.png';
import Img from '../Img';

const Logo = ({ light, sx }) => {
  const logoStyled = {
    display: 'inline-block',
    maxWidth: '270px',
    ...sx,
  };
  return (
    <Box as={Link} sx={logoStyled} to="/">
      <Img alt="Forella" src={light ? brandLogoWhite : brandLogo} />
    </Box>
  );
};
Logo.propTypes = {
  light: PropTypes.bool,
  sx: PropTypes.shape({}),
};

Logo.defaultProps = {
  light: false,
  sx: {},
};

export default Logo;
